@import "variables";
@import "mixins";

.about{
	background: $gree-color;
	padding: 90px 0;
	
	.about__elements{
		max-width: 1920px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
		z-index: 4;
	}

	.about__img{
		flex-basis: 50%;
		margin-right: 70px;
		position: relative;

				&::after{
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 4;
	}

		img{
			width: 100%;
			display: block;
		}

	}

	.about__info{
		flex-basis: calc(100% - 50% - 70px);
	}

	.info__title{
		font-size: 36px;
		font-weight: 700;
		line-height: 50px;
		letter-spacing: 2.04px;
		color: $title-color;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	.line{
		width: 60px;
		margin: 10px 0 15px 0;
		p{
		&::after{
			width: 60px;
			background-color: rgba(3, 57, 95, 1);
		}
	}
	}

	.info__des{
		letter-spacing: 0.8px;
		line-height: 25px;
		color: $desc-color;
		padding-right: 15px;
	
	}

	.about__button{

		a{
			text-decoration: none;
			color: $gree-color;
			font-weight: 600;
			line-height: 30px;
			background-color: rgba(3, 57, 95, 1);
			border-radius: 3px;
			display: inline-block;
			padding: 8px 25px;
			margin: 30px 0 ;
			transition-duration: 300ms;
			transition-property: background-color;
			&:hover{
				background-color: $blue-color;
			}
		}
	}
}

#slidingDiv{
display: none;
}

@media (max-width: 995px) {

        .about{
            padding: 45px 0;
         .about__img{
			margin-right: 25px;
			align-self: center;	
         }

		.about__info{
		flex-basis: calc(100% - 50% - 25px);
		}	
    }
}

@media (max-width: 860px) {
        .about{
             padding: 30px 0;
         }
}

@media (max-width: 750px) {
		.about__elements{
			flex-direction: column;

			.about__img{
			margin: 0 20px 30px 20px;
         }

         .about__info{
			margin: 0 20px 0px 20px;
		}
	}
}

@media (max-width: 668px) {
        .about{
             .info__title{
             	font-size: 32px;
             }
         }
}

@media (max-width: 468px) {

        .about{
        	padding: 25px 0;

            .info__title{
            font-size: 29px;
            }
        }
}