@import "variables";
@import "mixins";

.need{
	background: url('images/nead_trans1.jpg') top center no-repeat;
  	background-size: cover;
  	position: relative;
  	padding: 90px 0;
	
	&::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; 
}
	.need__des{
		font-size: 25px;
		font-weight: 600;
		line-height: 60px;
		letter-spacing: 1.8px;
		color: #dc291a;
		text-align: center;
		position: relative;
	    z-index: 2;
	    margin: 10px 0 50px 0;
	}

	.need__wrap{
		max-width: 700px;
		margin: 0 auto;
	}

	.need__form{
	background: $gree-color;
    background: rgba(0, 13, 37, 0.5);
    padding: 4em 3em;
	input[type="text"],input[type="tel"],textarea,.form-control {
    border: none;
    width: 100%;
    background: rgba(0, 13, 37, 0.7);
    padding: 10px 15px;
    margin-bottom: 15px;
    outline: none;
    font-size: 14px;
    color:$gree-color;
    letter-spacing: 1px;
    position: relative;
	z-index: 2;
	box-sizing: border-box;
}

	input[type="text"]:focus,input[type="tel"]:focus,textarea {
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);

	}

	 input[type="submit"] {
	 width: 100%;
    margin-top: 0px;
    padding: 12px 0px;
    font-size: 15px;
    font-weight: 600;
    color: $gree-color;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: none;
    background: rgba(220, 41, 26, 0.5);
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    transition: 0.5s all;
    position: relative;
	 z-index: 2;

	 &:hover{
	 	background: #B24D48;
	 }

	 }
}
}

@media (max-width: 995px) {

        .need{
            padding: 45px 0;
        }
}

@media (max-width: 860px) {

        .need{
            padding: 30px 0;

            .block-title{
            font-size: 36px;
            }
            .line{
            margin: 0px auto;   
            }

            .need__des{
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 1.6px;
        margin: 20px 0 35px 0;
    }
        }
}

@media (max-width: 668px) {

        .need{

            .block-title{
            font-size: 32px;
            }
            .need__des{
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 1.4px;
        margin: 20px 0 35px 0;
    }
        }
}

@media (max-width: 468px) {

        .need{
            padding: 25px 0;

            .block-title{
            font-size: 29px;
            }
            .need__des{
            margin: 20px 0 25px 0;
    }
        }
}
