@import "variables";
@import "mixins";

.services{
	background: #ccc;
  	 padding: 90px 0;

 	 .block-title{
		color: $title-color;

	}

	.line{
		p{
		&::after{
			background-color: $title-color;
		}
	}
	}

	.services__wrap{
	   margin-top: 40px;
	}

	.services__block{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.services__element{
			flex-basis: 31.6%;
			background-color: $gree-color;
			display: flex;
			flex-direction: column;

			img{
				display: block;
				max-width: 100%;
				border: 2px solid $white;
				box-sizing: border-box;
			}
		}

		.services__element__title{
			font-size: 16px;
			font-weight: 600;
			line-height: 25px;
			letter-spacing: 1.2px;
			color: $title-color;
			margin: 20px 0 20px 10px;
			text-transform: uppercase;
		}

		.line{
		margin: 0px 0 20px 10px;
		position: relative;
	    z-index: 2;
		p{
		&::after{
			background-color: $title-color;
			width: 50px;
		}
	}
	}
	.services__element__des{
		font-size: 15px;
		line-height: 20px;
		letter-spacing: 0.75px;
		color: $desc-color;
		margin: 0px 10px 20px 10px;
	}

	.element__button{
		display: flex;
		margin-top: auto;

		a{
			text-decoration: none;
			color: $gree-color;
			font-size: 14px;
			font-weight: 600;
			line-height: 30px;
			background-color: rgba(3, 57, 95, 1);
			border-radius: 3px;
			display: inline-block;
			padding: 6px 20px;
			margin: 0px 0 30px 10px;
			box-sizing: border-box; 
			transition-duration: 300ms;
			transition-property: background-color;
			&:hover{
				background-color: $blue-color;
			}
		}
	}

	}

	.services__button{

			max-width: 300px;
			margin: 60px auto 0 auto;
			
		a{
			text-decoration: none;
			color: $gree-color;;
			font-weight: 600;
			line-height: 30px;
			background-color: rgba(3, 57, 95, 1);
			border-radius: 3px;
			display: inline-block;
			padding: 8px 25px;
			transition-duration: 300ms;
			transition-property: background-color, color;
			&:hover{
				background-color: $blue-color;
			}
		}
	}
}

@media (max-width: 995px) {

		.services{
			padding: 45px 0;
		}
}

@media (max-width: 860px) {

		.services{
			padding: 30px 0;

			.block-title{
			font-size: 36px;
			}

			.line{
			margin: 0px auto;	
			}

			.services__block{
				justify-content: center;

				.services__element{
					flex-basis: 45%;
					margin: 0px 2.5% 20px 2.5%;
				}
			}

			.services__button{
				margin: 45px 0 0 20px;
			}

		}
}

@media (max-width: 668px) {
		.services{
			
			.block-title{
			font-size: 32px;
			}	
			
			.services__block{
				justify-content: center;

				.services__element{
					flex-basis: 100%;
					margin: 0px 0 15px 0;
					padding-top: 10px;

					.services__element__img{
						img{
							display: block;
							max-width: 100%;
							margin: 0 auto;
						}
					}

					.services__element__title{
					text-align: center;
				}
				.line{
					width: 60px;
					margin: 0 auto 20px auto;
				}

				.element__button{
					margin: 0 auto;
					a{
						display: block;
					}
				}

				}
			}
		}
}

@media (max-width: 468px) {
			.services{
			padding: 25px 0;

			.block-title{
			font-size: 29px;
			}

			.services__button{
				margin: 30px 0 0 20px;
			}	

		}
}
