@import "variables";
@import "mixins";
.menu{

	ul{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		li{
			padding: 5px;
			position: relative;

			&:hover > ul{
				display: block;
			}

			a{
				display: block;
				color: $gree-color;
				text-decoration: none;
				line-height: 30px;
				letter-spacing: 1.4px;
				padding: 10px 0;
				border-bottom: 2px solid transparent;
				transition-duration: 300ms;
				transition-property: color, border-bottom-color;
				&:hover{
					color: #0390FC;
					border-bottom-color: #0390FC;
					transition-duration: 300ms;
					transition-property: color, border-bottom-color;
				}
			}

			ul{
				position: absolute;
				min-width: 320px;
				background: rgba(3, 80, 134, 0.7);
				display: none;

				li{
					border: 1px solid $gree-color;

					a{
						padding-bottom: 5px;
						border-bottom: none;
						font-size: 14px;
					
					&:hover{
						text-decoration: underline;
					}
					}
				}
			}
		}
	}
//Submenu
		input[type="checkbox"],
		label.toggleSubmenu{
			display: none;
		}

		label.toggleMenu{
			background: rgba(3, 80, 134, 0.7);
			display: none;
			padding: 15px 0;
			color: $gree-color;
			text-transform: uppercase;
			cursor: pointer;
		}

		label.toggleMenu .fas{
			font-weight: 600;
			margin: 0 5px;
		}

}

@media all and (max-width: 768px) {

	.menu{

		ul{
			display: none;
			

			li{
				&:hover > ul{
				display: none;
			}

				a{
					padding-bottom: 0;
					border-bottom: none;

					&:hover{
						text-decoration: underline;
					}
				}
				a.subserv{
				background: rgba(3, 80, 134, 1);
				padding: 5px;	
				}
			}
		}

		li ul li a.subserv{
		background: rgba(3, 80, 134, 1);
	}
		label.toggleMenu{
			display: block;
		}

		input.toggleMenu:checked + label.toggleMenu{
			background: rgba(3, 80, 134, 1);
		}

		input.toggleMenu:checked ~ ul,
		input.toggleSubmenu:checked ~ ul{
			position: relative;
			display: block;
		}

		label.toggleSubmenu{
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			display: block;
		}

		input.toggleSubmenu:checked ~ a{
			background: rgba(3, 80, 134, 1);
		}

		label.toggleSubmenu .fas:before{
			content: "\f0d7";
			color: $gree-color;
			font-size: 18px;
		}

		label.toggleSubmenu .fas{
			position: absolute;
			top: 15px;
			right: 38px;
		}

		input.toggleSubmenu:checked ~ label.toggleSubmenu .fas:before{
			content: "\f0d8";
			color: $gree-color;
			font-size: 18px;
		}

	}

}

