@import "variables";
@import "mixins";

.gallery{
	padding: 90px 0 0px 0;
	background: rgba(3, 57, 95, 1);


	.gallery__block{
		display: flex;
		flex-wrap: wrap;
		max-width: 1920px;
		margin: 40px auto 0 auto;
		}

	.gallery__element{
		flex-basis: 25%;
		position: relative;
		z-index: 2;

		img{
			display: block;
			max-width: 100%;
			margin: 0 auto;
		}

		a{
			text-decoration: none;
			display: block;

			&::after{
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-color: rgba(3, 57, 95, 0.5);
				opacity: 0;
				transition-duration: 500ms;
				transition-property: opacity;
			}

			&:hover{
				&::after, p{
					opacity: 1;
				}
			}
		}

		p{
			position: absolute;
			left: 10%;
			bottom: 35px;
			z-index: 1;
			color: $gree-color;
			opacity: 0;
			transition-duration: 500ms;
			transition-property: opacity; 

			
			&::before{
				content: "";
				width: 44px;
				height: 3px;
				margin-bottom: 25px;
				background-color: $gree-color;
				display: block;
			}
		}
	}

	.gallery__button{
			max-width: 250px;
			margin: 60px auto 0 auto;
			
		a{
			text-decoration: none;
			color: rgba(3, 57, 95, 1);
			font-weight: 600;
			line-height: 30px;
			background-color: $gree-color;
			border-radius: 3px;
			display: inline-block;
			padding: 8px 25px;
			transition-duration: 300ms;
			transition-property: background-color, color;
			&:hover{
				background-color: #044573;
				color:  $gree-color;
			}
		}
	}

	#galleryDiv{
	display: none;
}
}

@media (max-width: 995px) {

		.gallery{
			padding: 45px 0 0px 0;
		}
}

@media (max-width: 860px) {

		.gallery{
			padding: 30px 0 0px 0;

			.block-title{
			font-size: 36px;
			}

			.gallery__element{
				flex-basis: 50%;
			}
		}
}

@media (max-width: 668px) {

		.gallery{

			.block-title{
			font-size: 32px;
			}

			.gallery__element{
				flex-basis: 100%;
			}
		}
}

@media (max-width: 468px) {

		.gallery{

			padding: 25px 0 0 0;

			.block-title{
			font-size: 29px;
			}
		}
}
