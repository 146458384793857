@mixin min-max($min-width, $max-width, $padding) {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 $padding;
  box-sizing: border-box;
  margin: 0 auto;
}

@mixin line($color-line: $gree-color){
	display: block;
	width: 100px;
	position: relative;
	margin: 30px auto 0px auto;
	p{
		&::after{
			content: "";
			width: 100px;
			height: 4px;
			position: relative;
		    z-index: 2;
			background-color: $gree-color;
			display: block;
		}
	}
}

@mixin block-title(){
	font-size: 40px;
	font-weight: 700;
	line-height: 60px;
	letter-spacing: 2.4px;
	position: relative;
	z-index: 2;
	color: $gree-color;
	text-transform: uppercase;
	text-align: center;	
}
