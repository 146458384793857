.about {
  background: #DCD8D7;
  padding: 90px 0; }
  .about .about__elements {
    max-width: 1920px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 4; }
  .about .about__img {
    flex-basis: 50%;
    margin-right: 70px;
    position: relative; }
    .about .about__img::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 4; }
    .about .about__img img {
      width: 100%;
      display: block; }
  .about .about__info {
    flex-basis: calc(100% - 50% - 70px); }
  .about .info__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 2.04px;
    color: #252523;
    text-transform: uppercase;
    margin-bottom: 15px; }
  .about .line {
    width: 60px;
    margin: 10px 0 15px 0; }
    .about .line p::after {
      width: 60px;
      background-color: #03395f; }
  .about .info__des {
    letter-spacing: 0.8px;
    line-height: 25px;
    color: #434343;
    padding-right: 15px; }
  .about .about__button a {
    text-decoration: none;
    color: #DCD8D7;
    font-weight: 600;
    line-height: 30px;
    background-color: #03395f;
    border-radius: 3px;
    display: inline-block;
    padding: 8px 25px;
    margin: 30px 0;
    transition-duration: 300ms;
    transition-property: background-color; }
    .about .about__button a:hover {
      background-color: #0468A6; }

#slidingDiv {
  display: none; }

@media (max-width: 995px) {
  .about {
    padding: 45px 0; }
    .about .about__img {
      margin-right: 25px;
      align-self: center; }
    .about .about__info {
      flex-basis: calc(100% - 50% - 25px); } }

@media (max-width: 860px) {
  .about {
    padding: 30px 0; } }

@media (max-width: 750px) {
  .about__elements {
    flex-direction: column; }
    .about__elements .about__img {
      margin: 0 20px 30px 20px; }
    .about__elements .about__info {
      margin: 0 20px 0px 20px; } }

@media (max-width: 668px) {
  .about .info__title {
    font-size: 32px; } }

@media (max-width: 468px) {
  .about {
    padding: 25px 0; }
    .about .info__title {
      font-size: 29px; } }

.arrow__up {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 20;
  opacity: 0.8; }

.block-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 2.4px;
  position: relative;
  z-index: 2;
  color: #DCD8D7;
  text-transform: uppercase;
  text-align: center; }

.clients {
  padding: 90px 0;
  background: url("images/client_bg.jpg") top center no-repeat;
  background-size: cover;
  position: relative; }
  .clients::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  .clients .cliens__wrap {
    position: relative;
    z-index: 2;
    padding-top: 40px; }
  .clients .clents__block .clients__elements img {
    display: block;
    max-width: 280px;
    margin: 0 auto;
    border: 2px solid #DCD8D7;
    box-sizing: border-box; }
  .clients .clents__block .clients__elements .client__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 1.2px;
    color: #DCD8D7;
    margin: 20px 0 0px 0px;
    text-transform: uppercase;
    text-align: center; }
  .clients .clents__block .clients__elements .client__info {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;
    text-align: center;
    color: #DCD8D7;
    display: flex;
    margin: 20px 5px 40px 5px;
    border-top: 2px solid #DCD8D7;
    padding-top: 5px;
    box-sizing: border-box;
    opacity: 0.8; }
  .clients .slides {
    position: relative; }
  .clients .slick-dots li button {
    border: 1px solid #DCD8D7;
    border-radius: 50%; }
  .clients .slick-dots li button:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #0A0F1A;
    opacity: 1;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin: -5px 0 0 -5px; }
  .clients .slick-dots li button:hover,
  .clients .slick-dots li.slick-active button {
    border-color: #DCD8D7; }
  .clients .slick-dots li button:hover::before,
  .clients .slick-dots li.slick-active button::before {
    background: #DCD8D7; }

@media (max-width: 995px) {
  .clients {
    padding: 45px 0; } }

@media (max-width: 860px) {
  .clients {
    padding: 30px 0; }
    .clients .block-title {
      font-size: 36px; }
    .clients .line {
      margin: 0px auto; } }

@media (max-width: 668px) {
  .clients .block-title {
    font-size: 32px; } }

@media (max-width: 468px) {
  .clients {
    padding: 25px 0; }
    .clients .block-title {
      font-size: 29px; } }

.contacts {
  background: #ccc;
  padding: 90px 0; }
  .contacts .contacts__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .contacts .contacts__left {
    flex-basis: 40.8%;
    background-color: #DCD8D7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px/5px;
    padding: 20px 60px;
    box-sizing: border-box; }
  .contacts .contacts__right {
    flex-basis: 56.8%;
    background-color: #DCD8D7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px/5px;
    padding: 0px 60px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .contacts .contacts__right .contacts__form {
      flex-basis: 100%; }
  .contacts .contacts__info {
    margin: 35px 0; }
    .contacts .contacts__info p {
      text-align: left; }
    .contacts .contacts__info .contacts__info__title {
      font-size: 36px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 2.04px;
      color: #252523;
      text-transform: uppercase; }
    .contacts .contacts__info .line {
      width: 60px;
      margin: 10px 0 0px 0; }
      .contacts .contacts__info .line p::after {
        width: 60px;
        background-color: #03395f; }
    .contacts .contacts__info .contacts__des {
      font-size: 16px;
      color: #000F29;
      line-height: 30px;
      letter-spacing: 0.9px; }
      .contacts .contacts__info .contacts__des .contacts__des__mail {
        color: #000F29;
        text-decoration: none;
        cursor: pointer; }
        .contacts .contacts__info .contacts__des .contacts__des__mail:hover {
          text-decoration: underline; }
  .contacts .contacts__form .contacts__input, .contacts .contacts__form .contacts__textarea {
    position: relative;
    margin: 20px 0; }
    .contacts .contacts__form .contacts__input input, .contacts .contacts__form .contacts__input textarea, .contacts .contacts__form .contacts__textarea input, .contacts .contacts__form .contacts__textarea textarea {
      background: #DCD8D7;
      color: #03395f;
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;
      line-height: 30px;
      letter-spacing: 0.9px;
      width: 100%;
      border: none;
      border-bottom: 1px solid #03395f;
      box-shadow: none;
      padding: 7px 0; }
      .contacts .contacts__form .contacts__input input:focus + label, .contacts .contacts__form .contacts__input textarea:focus + label, .contacts .contacts__form .contacts__textarea input:focus + label, .contacts .contacts__form .contacts__textarea textarea:focus + label {
        transform: translateY(-20px);
        font-size: 14px; }
    .contacts .contacts__form .contacts__input textarea, .contacts .contacts__form .contacts__textarea textarea {
      resize: none;
      outline: none;
      height: 100px; }
    .contacts .contacts__form .contacts__input label, .contacts .contacts__form .contacts__textarea label {
      background: #DCD8D7;
      color: #03395f;
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;
      line-height: 30px;
      letter-spacing: 0.9px;
      width: 100%;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition-duration: 300ms; }
  .contacts .contacts__form .contacts__button button {
    cursor: pointer;
    color: #DCD8D7;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    line-height: 30px;
    background-color: #03395f;
    border: none;
    border-radius: 3px;
    display: block;
    padding: 8px 25px;
    margin-top: 30px;
    transition-duration: 300ms;
    transition-property: background-color; }
    .contacts .contacts__form .contacts__button button:hover {
      background-color: #0468A6; }

@media (max-width: 995px) {
  .contacts {
    padding: 45px 0; }
    .contacts .contacts__left {
      padding: 30px; }
    .contacts .contacts__right {
      padding: 0 30px; } }

@media (max-width: 860px) {
  .contacts {
    padding: 30px 0; } }

@media (max-width: 770px) {
  .contacts .contacts__info {
    margin: 25px 0; }
  .contacts .contacts__left {
    flex-basis: 100%;
    margin: 15px 0; }
  .contacts .contacts__right {
    flex-basis: 100%;
    margin: 15px 0;
    padding: 30px; } }

@media (max-width: 668px) {
  .contacts .contacts__info .contacts__info__title {
    font-size: 32px; } }

@media (max-width: 468px) {
  .contacts {
    padding: 25px 0; }
    .contacts .contacts__info .contacts__info__title {
      font-size: 29px; } }

footer {
  background: #03395f; }
  footer .footer__wrap {
    padding: 40px; }
    footer .footer__wrap .footer__block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      footer .footer__wrap .footer__block .footer__block__element {
        flex-basis: 33%; }
        footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element {
          display: flex;
          align-items: center;
          margin-bottom: 15px; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element img {
            display: block;
            max-width: 100%;
            margin-right: 15px;
            border: 2px solid #DCD8D7;
            box-sizing: border-box; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element a {
            text-decoration: none;
            color: #DCD8D7;
            margin-bottom: 15px;
            display: block;
            cursor: pointer; }
            footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element a:hover {
              opacity: 0.6; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element .footer__top__news__date {
            font-size: 12px;
            color: #DCD8D7;
            opacity: 0.6; }
        footer .footer__wrap .footer__block .footer__block__element .news__block__title, footer .footer__wrap .footer__block .footer__block__element .footer__top__links__title,
        footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts__title {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 1.5px;
          color: #DCD8D7;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 25px;
          text-decoration: underline; }
        footer .footer__wrap .footer__block .footer__block__element .footer__top__services a {
          text-decoration: none;
          font-size: 18px;
          color: #DCD8D7;
          margin-bottom: 25px;
          display: block;
          cursor: pointer; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__services a:hover {
            opacity: 0.6; }
        footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__tel, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__email, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__address {
          font-size: 18px;
          color: #DCD8D7;
          margin-bottom: 25px;
          text-align: center;
          background: rgba(5, 77, 129, 0.6);
          padding: 20px 15px; }
  footer .footer__bottom {
    background: rgba(5, 77, 129, 0.6);
    padding: 30px 0;
    position: relative;
    z-index: 2; }
    footer .footer__bottom p {
      font-size: 14px;
      color: #DCD8D7;
      text-align: center; }
      footer .footer__bottom p a {
        text-decoration: none;
        color: #DCD8D7; }
        footer .footer__bottom p a:hover {
          text-decoration: underline;
          opacity: 0.6; }

@media (max-width: 1080px) {
  footer .footer__wrap .footer__block {
    justify-content: center; }
    footer .footer__wrap .footer__block .footer__block__element {
      flex-basis: 50%; }
      footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element img {
        margin-right: 25px; }
      footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__tel, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__email, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__address {
        text-align: left; }
      footer .footer__wrap .footer__block .footer__block__element .news__block__title, footer .footer__wrap .footer__block .footer__block__element .footer__top__links__title,
      footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts__title {
        text-align: left; } }

@media (max-width: 768px) {
  footer .footer__wrap .footer__block .footer__block__element {
    flex-basis: 100%; } }

.gallery {
  padding: 90px 0 0px 0;
  background: #03395f; }
  .gallery .gallery__block {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 40px auto 0 auto; }
  .gallery .gallery__element {
    flex-basis: 25%;
    position: relative;
    z-index: 2; }
    .gallery .gallery__element img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
    .gallery .gallery__element a {
      text-decoration: none;
      display: block; }
      .gallery .gallery__element a::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(3, 57, 95, 0.5);
        opacity: 0;
        transition-duration: 500ms;
        transition-property: opacity; }
      .gallery .gallery__element a:hover::after, .gallery .gallery__element a:hover p {
        opacity: 1; }
    .gallery .gallery__element p {
      position: absolute;
      left: 10%;
      bottom: 35px;
      z-index: 1;
      color: #DCD8D7;
      opacity: 0;
      transition-duration: 500ms;
      transition-property: opacity; }
      .gallery .gallery__element p::before {
        content: "";
        width: 44px;
        height: 3px;
        margin-bottom: 25px;
        background-color: #DCD8D7;
        display: block; }
  .gallery .gallery__button {
    max-width: 250px;
    margin: 60px auto 0 auto; }
    .gallery .gallery__button a {
      text-decoration: none;
      color: #03395f;
      font-weight: 600;
      line-height: 30px;
      background-color: #DCD8D7;
      border-radius: 3px;
      display: inline-block;
      padding: 8px 25px;
      transition-duration: 300ms;
      transition-property: background-color, color; }
      .gallery .gallery__button a:hover {
        background-color: #044573;
        color: #DCD8D7; }
  .gallery #galleryDiv {
    display: none; }

@media (max-width: 995px) {
  .gallery {
    padding: 45px 0 0px 0; } }

@media (max-width: 860px) {
  .gallery {
    padding: 30px 0 0px 0; }
    .gallery .block-title {
      font-size: 36px; }
    .gallery .gallery__element {
      flex-basis: 50%; } }

@media (max-width: 668px) {
  .gallery .block-title {
    font-size: 32px; }
  .gallery .gallery__element {
    flex-basis: 100%; } }

@media (max-width: 468px) {
  .gallery {
    padding: 25px 0 0 0; }
    .gallery .block-title {
      font-size: 29px; } }

header {
  background: url("images/header_bg2.jpg") top center no-repeat;
  background-size: cover;
  position: relative; }
  header::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  header .header__top {
    padding: 20px 0;
    background: rgba(3, 80, 134, 0.5);
    position: relative;
    z-index: 5; }
  header .header__top__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
  header .heder__top__logo img:hover {
    opacity: 0.65;
    transition-duration: 300ms;
    transition-property: opacity; }
  header .header__top__menu {
    flex-basis: 63%; }
    header .header__top__menu a.c-active {
      color: #0390FC; }
      header .header__top__menu a.c-active:hover {
        color: #DCD8D7;
        border-bottom-color: #DCD8D7; }
  header .header__content {
    position: relative;
    z-index: 2; }
    header .header__content .line {
      margin: 140px 0 20px 0; }
      header .header__content .line p::after {
        background-color: #DCD8D7; }
    header .header__content h1 {
      font-size: 60px;
      font-weight: 700;
      line-height: 75px;
      letter-spacing: 3.5px;
      color: #DCD8D7;
      text-transform: uppercase;
      margin-bottom: 105px; }
    header .header__content .header__icon {
      max-width: 62px;
      margin: 0px auto; }
      header .header__content .header__icon a {
        display: block; }
        header .header__content .header__icon a:hover img {
          animation-duration: 3s;
          animation-delay: 2s;
          animation-iteration-count: infinite; }

@media (max-width: 1200px) {
  header .header__top__section .heder__top__logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px; }
  header .header__top__section .header__top__menu {
    flex-basis: 100%; }
  header .header__content .line {
    margin: 60px 0 20px 0; }
  header .header__content h1 {
    font-size: 48px;
    margin-bottom: 50px; } }

@media (max-width: 668px) {
  header .header__top__section .heder__top__logo {
    margin-bottom: 20px; }
  header .header__content .line {
    margin: 60px 0 20px 0; }
  header .header__content h1 {
    margin-bottom: 30px; } }

@media (max-width: 468px) {
  header .header__top__section .heder__top__logo {
    margin-bottom: 15px; }
  header .header__content .line {
    margin: 40px 0 20px 0; }
  header .header__content h1 {
    font-size: 45px;
    margin-bottom: 25px; } }

.line {
  display: block;
  width: 100px;
  position: relative;
  margin: 30px auto 0px auto; }
  .line p::after {
    content: "";
    width: 100px;
    height: 4px;
    position: relative;
    z-index: 2;
    background-color: #DCD8D7;
    display: block; }

.menu ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .menu ul li {
    padding: 5px;
    position: relative; }
    .menu ul li:hover > ul {
      display: block; }
    .menu ul li a {
      display: block;
      color: #DCD8D7;
      text-decoration: none;
      line-height: 30px;
      letter-spacing: 1.4px;
      padding: 10px 0;
      border-bottom: 2px solid transparent;
      transition-duration: 300ms;
      transition-property: color, border-bottom-color; }
      .menu ul li a:hover {
        color: #0390FC;
        border-bottom-color: #0390FC;
        transition-duration: 300ms;
        transition-property: color, border-bottom-color; }
    .menu ul li ul {
      position: absolute;
      min-width: 320px;
      background: rgba(3, 80, 134, 0.7);
      display: none; }
      .menu ul li ul li {
        border: 1px solid #DCD8D7; }
        .menu ul li ul li a {
          padding-bottom: 5px;
          border-bottom: none;
          font-size: 14px; }
          .menu ul li ul li a:hover {
            text-decoration: underline; }

.menu input[type="checkbox"],
.menu label.toggleSubmenu {
  display: none; }

.menu label.toggleMenu {
  background: rgba(3, 80, 134, 0.7);
  display: none;
  padding: 15px 0;
  color: #DCD8D7;
  text-transform: uppercase;
  cursor: pointer; }

.menu label.toggleMenu .fas {
  font-weight: 600;
  margin: 0 5px; }

@media all and (max-width: 768px) {
  .menu ul {
    display: none; }
    .menu ul li:hover > ul {
      display: none; }
    .menu ul li a {
      padding-bottom: 0;
      border-bottom: none; }
      .menu ul li a:hover {
        text-decoration: underline; }
    .menu ul li a.subserv {
      background: #035086;
      padding: 5px; }
  .menu li ul li a.subserv {
    background: #035086; }
  .menu label.toggleMenu {
    display: block; }
  .menu input.toggleMenu:checked + label.toggleMenu {
    background: #035086; }
  .menu input.toggleMenu:checked ~ ul,
  .menu input.toggleSubmenu:checked ~ ul {
    position: relative;
    display: block; }
  .menu label.toggleSubmenu {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block; }
  .menu input.toggleSubmenu:checked ~ a {
    background: #035086; }
  .menu label.toggleSubmenu .fas:before {
    content: "\f0d7";
    color: #DCD8D7;
    font-size: 18px; }
  .menu label.toggleSubmenu .fas {
    position: absolute;
    top: 15px;
    right: 38px; }
  .menu input.toggleSubmenu:checked ~ label.toggleSubmenu .fas:before {
    content: "\f0d8";
    color: #DCD8D7;
    font-size: 18px; } }

.need {
  background: url("images/nead_trans1.jpg") top center no-repeat;
  background-size: cover;
  position: relative;
  padding: 90px 0; }
  .need::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  .need .need__des {
    font-size: 25px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 1.8px;
    color: #dc291a;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 10px 0 50px 0; }
  .need .need__wrap {
    max-width: 700px;
    margin: 0 auto; }
  .need .need__form {
    background: #DCD8D7;
    background: rgba(0, 13, 37, 0.5);
    padding: 4em 3em; }
    .need .need__form input[type="text"], .need .need__form input[type="tel"], .need .need__form textarea, .need .need__form .form-control {
      border: none;
      width: 100%;
      background: rgba(0, 13, 37, 0.7);
      padding: 10px 15px;
      margin-bottom: 15px;
      outline: none;
      font-size: 14px;
      color: #DCD8D7;
      letter-spacing: 1px;
      position: relative;
      z-index: 2;
      box-sizing: border-box; }
    .need .need__form input[type="text"]:focus, .need .need__form input[type="tel"]:focus, .need .need__form textarea {
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
    .need .need__form input[type="submit"] {
      width: 100%;
      margin-top: 0px;
      padding: 12px 0px;
      font-size: 15px;
      font-weight: 600;
      color: #DCD8D7;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: none;
      background: rgba(220, 41, 26, 0.5);
      -webkit-transition: 0.5s all;
      -o-transition: 0.5s all;
      -moz-transition: 0.5s all;
      -ms-transition: 0.5s all;
      transition: 0.5s all;
      position: relative;
      z-index: 2; }
      .need .need__form input[type="submit"]:hover {
        background: #B24D48; }

@media (max-width: 995px) {
  .need {
    padding: 45px 0; } }

@media (max-width: 860px) {
  .need {
    padding: 30px 0; }
    .need .block-title {
      font-size: 36px; }
    .need .line {
      margin: 0px auto; }
    .need .need__des {
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 1.6px;
      margin: 20px 0 35px 0; } }

@media (max-width: 668px) {
  .need .block-title {
    font-size: 32px; }
  .need .need__des {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 1.4px;
    margin: 20px 0 35px 0; } }

@media (max-width: 468px) {
  .need {
    padding: 25px 0; }
    .need .block-title {
      font-size: 29px; }
    .need .need__des {
      margin: 20px 0 25px 0; } }

.services {
  background: #ccc;
  padding: 90px 0; }
  .services .block-title {
    color: #252523; }
  .services .line p::after {
    background-color: #252523; }
  .services .services__wrap {
    margin-top: 40px; }
  .services .services__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .services .services__block .services__element {
      flex-basis: 31.6%;
      background-color: #DCD8D7;
      display: flex;
      flex-direction: column; }
      .services .services__block .services__element img {
        display: block;
        max-width: 100%;
        border: 2px solid #fff;
        box-sizing: border-box; }
    .services .services__block .services__element__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1.2px;
      color: #252523;
      margin: 20px 0 20px 10px;
      text-transform: uppercase; }
    .services .services__block .line {
      margin: 0px 0 20px 10px;
      position: relative;
      z-index: 2; }
      .services .services__block .line p::after {
        background-color: #252523;
        width: 50px; }
    .services .services__block .services__element__des {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.75px;
      color: #434343;
      margin: 0px 10px 20px 10px; }
    .services .services__block .element__button {
      display: flex;
      margin-top: auto; }
      .services .services__block .element__button a {
        text-decoration: none;
        color: #DCD8D7;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        background-color: #03395f;
        border-radius: 3px;
        display: inline-block;
        padding: 6px 20px;
        margin: 0px 0 30px 10px;
        box-sizing: border-box;
        transition-duration: 300ms;
        transition-property: background-color; }
        .services .services__block .element__button a:hover {
          background-color: #0468A6; }
  .services .services__button {
    max-width: 300px;
    margin: 60px auto 0 auto; }
    .services .services__button a {
      text-decoration: none;
      color: #DCD8D7;
      font-weight: 600;
      line-height: 30px;
      background-color: #03395f;
      border-radius: 3px;
      display: inline-block;
      padding: 8px 25px;
      transition-duration: 300ms;
      transition-property: background-color, color; }
      .services .services__button a:hover {
        background-color: #0468A6; }

@media (max-width: 995px) {
  .services {
    padding: 45px 0; } }

@media (max-width: 860px) {
  .services {
    padding: 30px 0; }
    .services .block-title {
      font-size: 36px; }
    .services .line {
      margin: 0px auto; }
    .services .services__block {
      justify-content: center; }
      .services .services__block .services__element {
        flex-basis: 45%;
        margin: 0px 2.5% 20px 2.5%; }
    .services .services__button {
      margin: 45px 0 0 20px; } }

@media (max-width: 668px) {
  .services .block-title {
    font-size: 32px; }
  .services .services__block {
    justify-content: center; }
    .services .services__block .services__element {
      flex-basis: 100%;
      margin: 0px 0 15px 0;
      padding-top: 10px; }
      .services .services__block .services__element .services__element__img img {
        display: block;
        max-width: 100%;
        margin: 0 auto; }
      .services .services__block .services__element .services__element__title {
        text-align: center; }
      .services .services__block .services__element .line {
        width: 60px;
        margin: 0 auto 20px auto; }
      .services .services__block .services__element .element__button {
        margin: 0 auto; }
        .services .services__block .services__element .element__button a {
          display: block; } }

@media (max-width: 468px) {
  .services {
    padding: 25px 0; }
    .services .block-title {
      font-size: 29px; }
    .services .services__button {
      margin: 30px 0 0 20px; } }

.scrollup {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  opacity: 0.6;
  display: none;
  text-indent: -9999px;
  border-radius: 5px;
  z-index: 10;
  cursor: pointer; }

.hed__services {
  background: #00274B;
  padding: 20px 0; }
  .hed__services .hed__services__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
  .hed__services .hed__services__menu-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-basis: 70%; }
  .hed__services .hed__services__menu {
    display: flex;
    flex-basis: 30%;
    justify-content: space-between; }
    .hed__services .hed__services__menu a {
      color: #DCD8D7;
      text-decoration: none;
      line-height: 30px;
      letter-spacing: 1.4px;
      padding: 10px 0;
      border-bottom: 2px solid transparent;
      transition-duration: 300ms;
      transition-property: color, border-bottom-color; }
      .hed__services .hed__services__menu a:hover {
        color: #0390FC;
        border-bottom-color: #0390FC;
        transition-duration: 300ms;
        transition-property: color, border-bottom-color; }
    .hed__services .hed__services__menu a.c-active {
      color: #0390FC;
      border-bottom: none; }
  .hed__services .hed__services__info {
    display: flex; }
    .hed__services .hed__services__info p img {
      vertical-align: middle;
      margin-right: 5px; }
    .hed__services .hed__services__info .top__telefon {
      color: #DCD8D7;
      font-weight: 600; }
    .hed__services .hed__services__info .top__email {
      margin-left: 15px; }
      .hed__services .hed__services__info .top__email a {
        color: #DCD8D7;
        text-decoration: none;
        letter-spacing: 0.6px; }
  .hed__services .hed__services__logo img:hover {
    opacity: 0.65;
    transition-duration: 300ms;
    transition-property: opacity; }

.serv-top-bg {
  position: relative; }
  .serv-top-bg::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(3, 80, 134, 0.4);
    z-index: 4; }
  .serv-top-bg img {
    width: 100%;
    display: block; }

.all__services__wrap {
  margin-bottom: 30px; }

.all__services {
  background: #ccc;
  padding: 30px 0; }
  .all__services .block-title {
    color: #252523;
    font-size: 35px; }
  .all__services .line {
    width: 70px;
    margin: 10px auto 0px auto; }
    .all__services .line p::after {
      background-color: #252523; }

.all__services__block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 50px; }
  .all__services__block .all__services__element__img {
    flex-basis: 50%;
    margin-right: 35px;
    position: relative; }
    .all__services__block .all__services__element__img::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 4; }
    .all__services__block .all__services__element__img img {
      width: 100%;
      display: block; }
  .all__services__block .all__services__element__info {
    flex-basis: calc(100% - 50% - 35px);
    letter-spacing: 0.8px;
    line-height: 25px;
    color: #434343; }
    .all__services__block .all__services__element__info span {
      color: #E05555;
      font-weight: 600; }

@media (max-width: 960px) {
  .hed__services .hed__services__logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px; }
  .hed__services .hed__services__menu-info {
    flex-basis: 100%; }
  .all__services__wrap .block-title {
    line-height: 40px; }
  .all__services__wrap .all__services__block {
    padding-top: 30px; }
  .all__services__wrap .all__services__element__img {
    margin-right: 25px; }
  .all__services__wrap .all__services__element__info {
    flex-basis: calc(100% - 50% - 25px); } }

@media (max-width: 868px) {
  .all__services__wrap .block-title {
    font-size: 30px;
    line-height: 30px; }
  .all__services__block {
    flex-direction: column; }
    .all__services__block .all__services__element__img {
      order: -1;
      margin: 0 auto 20px auto; }
    .all__services__block .all__services__element__info {
      text-align: justify; } }

@media (max-width: 625px) {
  .hed__services .hed__services__logo img {
    width: 220px; }
  .hed__services .hed__services__menu {
    flex-basis: 100%;
    justify-content: center; }
    .hed__services .hed__services__menu p {
      margin-right: 20px; }
  .hed__services .hed__services__info {
    padding-top: 10px;
    flex-basis: 100%;
    justify-content: center; }
  .all__services__wrap .block-title {
    font-size: 25px;
    line-height: 30px; }
  .all__services__block .all__services__element__info {
    text-align: left; } }

@media (max-width: 378px) {
  .hed__services__menu p,
  .hed__services__info p {
    font-size: 14px; }
  .all__services__wrap .block-title {
    font-size: 22px;
    line-height: 22px; } }

@charset "UTF-8";
/**
 * Сброс стилей
 **/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

input {
  outline: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px; }

.container {
  min-width: 320px;
  max-width: 100%;
  padding: 0 0;
  box-sizing: border-box;
  margin: 0 auto; }

.wrap {
  min-width: 320px;
  max-width: 1200px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto; }

header {
  background: url("images/header_bg2.jpg") top center no-repeat;
  background-size: cover;
  position: relative; }
  header::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  header .header__top {
    padding: 20px 0;
    background: rgba(3, 80, 134, 0.5);
    position: relative;
    z-index: 5; }
  header .header__top__section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
  header .heder__top__logo img:hover {
    opacity: 0.65;
    transition-duration: 300ms;
    transition-property: opacity; }
  header .header__top__menu {
    flex-basis: 63%; }
    header .header__top__menu a.c-active {
      color: #0390FC; }
      header .header__top__menu a.c-active:hover {
        color: #DCD8D7;
        border-bottom-color: #DCD8D7; }
  header .header__content {
    position: relative;
    z-index: 2; }
    header .header__content .line {
      margin: 140px 0 20px 0; }
      header .header__content .line p::after {
        background-color: #DCD8D7; }
    header .header__content h1 {
      font-size: 60px;
      font-weight: 700;
      line-height: 75px;
      letter-spacing: 3.5px;
      color: #DCD8D7;
      text-transform: uppercase;
      margin-bottom: 105px; }
    header .header__content .header__icon {
      max-width: 62px;
      margin: 0px auto; }
      header .header__content .header__icon a {
        display: block; }
        header .header__content .header__icon a:hover img {
          animation-duration: 3s;
          animation-delay: 2s;
          animation-iteration-count: infinite; }

@media (max-width: 1200px) {
  header .header__top__section .heder__top__logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px; }
  header .header__top__section .header__top__menu {
    flex-basis: 100%; }
  header .header__content .line {
    margin: 60px 0 20px 0; }
  header .header__content h1 {
    font-size: 48px;
    margin-bottom: 50px; } }

@media (max-width: 668px) {
  header .header__top__section .heder__top__logo {
    margin-bottom: 20px; }
  header .header__content .line {
    margin: 60px 0 20px 0; }
  header .header__content h1 {
    margin-bottom: 30px; } }

@media (max-width: 468px) {
  header .header__top__section .heder__top__logo {
    margin-bottom: 15px; }
  header .header__content .line {
    margin: 40px 0 20px 0; }
  header .header__content h1 {
    font-size: 45px;
    margin-bottom: 25px; } }

.menu ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .menu ul li {
    padding: 5px;
    position: relative; }
    .menu ul li:hover > ul {
      display: block; }
    .menu ul li a {
      display: block;
      color: #DCD8D7;
      text-decoration: none;
      line-height: 30px;
      letter-spacing: 1.4px;
      padding: 10px 0;
      border-bottom: 2px solid transparent;
      transition-duration: 300ms;
      transition-property: color, border-bottom-color; }
      .menu ul li a:hover {
        color: #0390FC;
        border-bottom-color: #0390FC;
        transition-duration: 300ms;
        transition-property: color, border-bottom-color; }
    .menu ul li ul {
      position: absolute;
      min-width: 320px;
      background: rgba(3, 80, 134, 0.7);
      display: none; }
      .menu ul li ul li {
        border: 1px solid #DCD8D7; }
        .menu ul li ul li a {
          padding-bottom: 5px;
          border-bottom: none;
          font-size: 14px; }
          .menu ul li ul li a:hover {
            text-decoration: underline; }

.menu input[type="checkbox"],
.menu label.toggleSubmenu {
  display: none; }

.menu label.toggleMenu {
  background: rgba(3, 80, 134, 0.7);
  display: none;
  padding: 15px 0;
  color: #DCD8D7;
  text-transform: uppercase;
  cursor: pointer; }

.menu label.toggleMenu .fas {
  font-weight: 600;
  margin: 0 5px; }

@media all and (max-width: 768px) {
  .menu ul {
    display: none; }
    .menu ul li:hover > ul {
      display: none; }
    .menu ul li a {
      padding-bottom: 0;
      border-bottom: none; }
      .menu ul li a:hover {
        text-decoration: underline; }
    .menu ul li a.subserv {
      background: #035086;
      padding: 5px; }
  .menu li ul li a.subserv {
    background: #035086; }
  .menu label.toggleMenu {
    display: block; }
  .menu input.toggleMenu:checked + label.toggleMenu {
    background: #035086; }
  .menu input.toggleMenu:checked ~ ul,
  .menu input.toggleSubmenu:checked ~ ul {
    position: relative;
    display: block; }
  .menu label.toggleSubmenu {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block; }
  .menu input.toggleSubmenu:checked ~ a {
    background: #035086; }
  .menu label.toggleSubmenu .fas:before {
    content: "\f0d7";
    color: #DCD8D7;
    font-size: 18px; }
  .menu label.toggleSubmenu .fas {
    position: absolute;
    top: 15px;
    right: 38px; }
  .menu input.toggleSubmenu:checked ~ label.toggleSubmenu .fas:before {
    content: "\f0d8";
    color: #DCD8D7;
    font-size: 18px; } }

.line {
  display: block;
  width: 100px;
  position: relative;
  margin: 30px auto 0px auto; }
  .line p::after {
    content: "";
    width: 100px;
    height: 4px;
    position: relative;
    z-index: 2;
    background-color: #DCD8D7;
    display: block; }

.services {
  background: #ccc;
  padding: 90px 0; }
  .services .block-title {
    color: #252523; }
  .services .line p::after {
    background-color: #252523; }
  .services .services__wrap {
    margin-top: 40px; }
  .services .services__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .services .services__block .services__element {
      flex-basis: 31.6%;
      background-color: #DCD8D7;
      display: flex;
      flex-direction: column; }
      .services .services__block .services__element img {
        display: block;
        max-width: 100%;
        border: 2px solid #fff;
        box-sizing: border-box; }
    .services .services__block .services__element__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1.2px;
      color: #252523;
      margin: 20px 0 20px 10px;
      text-transform: uppercase; }
    .services .services__block .line {
      margin: 0px 0 20px 10px;
      position: relative;
      z-index: 2; }
      .services .services__block .line p::after {
        background-color: #252523;
        width: 50px; }
    .services .services__block .services__element__des {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.75px;
      color: #434343;
      margin: 0px 10px 20px 10px; }
    .services .services__block .element__button {
      display: flex;
      margin-top: auto; }
      .services .services__block .element__button a {
        text-decoration: none;
        color: #DCD8D7;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        background-color: #03395f;
        border-radius: 3px;
        display: inline-block;
        padding: 6px 20px;
        margin: 0px 0 30px 10px;
        box-sizing: border-box;
        transition-duration: 300ms;
        transition-property: background-color; }
        .services .services__block .element__button a:hover {
          background-color: #0468A6; }
  .services .services__button {
    max-width: 300px;
    margin: 60px auto 0 auto; }
    .services .services__button a {
      text-decoration: none;
      color: #DCD8D7;
      font-weight: 600;
      line-height: 30px;
      background-color: #03395f;
      border-radius: 3px;
      display: inline-block;
      padding: 8px 25px;
      transition-duration: 300ms;
      transition-property: background-color, color; }
      .services .services__button a:hover {
        background-color: #0468A6; }

@media (max-width: 995px) {
  .services {
    padding: 45px 0; } }

@media (max-width: 860px) {
  .services {
    padding: 30px 0; }
    .services .block-title {
      font-size: 36px; }
    .services .line {
      margin: 0px auto; }
    .services .services__block {
      justify-content: center; }
      .services .services__block .services__element {
        flex-basis: 45%;
        margin: 0px 2.5% 20px 2.5%; }
    .services .services__button {
      margin: 45px 0 0 20px; } }

@media (max-width: 668px) {
  .services .block-title {
    font-size: 32px; }
  .services .services__block {
    justify-content: center; }
    .services .services__block .services__element {
      flex-basis: 100%;
      margin: 0px 0 15px 0;
      padding-top: 10px; }
      .services .services__block .services__element .services__element__img img {
        display: block;
        max-width: 100%;
        margin: 0 auto; }
      .services .services__block .services__element .services__element__title {
        text-align: center; }
      .services .services__block .services__element .line {
        width: 60px;
        margin: 0 auto 20px auto; }
      .services .services__block .services__element .element__button {
        margin: 0 auto; }
        .services .services__block .services__element .element__button a {
          display: block; } }

@media (max-width: 468px) {
  .services {
    padding: 25px 0; }
    .services .block-title {
      font-size: 29px; }
    .services .services__button {
      margin: 30px 0 0 20px; } }

.gallery {
  padding: 90px 0 0px 0;
  background: #03395f; }
  .gallery .gallery__block {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 40px auto 0 auto; }
  .gallery .gallery__element {
    flex-basis: 25%;
    position: relative;
    z-index: 2; }
    .gallery .gallery__element img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
    .gallery .gallery__element a {
      text-decoration: none;
      display: block; }
      .gallery .gallery__element a::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(3, 57, 95, 0.5);
        opacity: 0;
        transition-duration: 500ms;
        transition-property: opacity; }
      .gallery .gallery__element a:hover::after, .gallery .gallery__element a:hover p {
        opacity: 1; }
    .gallery .gallery__element p {
      position: absolute;
      left: 10%;
      bottom: 35px;
      z-index: 1;
      color: #DCD8D7;
      opacity: 0;
      transition-duration: 500ms;
      transition-property: opacity; }
      .gallery .gallery__element p::before {
        content: "";
        width: 44px;
        height: 3px;
        margin-bottom: 25px;
        background-color: #DCD8D7;
        display: block; }
  .gallery .gallery__button {
    max-width: 250px;
    margin: 60px auto 0 auto; }
    .gallery .gallery__button a {
      text-decoration: none;
      color: #03395f;
      font-weight: 600;
      line-height: 30px;
      background-color: #DCD8D7;
      border-radius: 3px;
      display: inline-block;
      padding: 8px 25px;
      transition-duration: 300ms;
      transition-property: background-color, color; }
      .gallery .gallery__button a:hover {
        background-color: #044573;
        color: #DCD8D7; }
  .gallery #galleryDiv {
    display: none; }

@media (max-width: 995px) {
  .gallery {
    padding: 45px 0 0px 0; } }

@media (max-width: 860px) {
  .gallery {
    padding: 30px 0 0px 0; }
    .gallery .block-title {
      font-size: 36px; }
    .gallery .gallery__element {
      flex-basis: 50%; } }

@media (max-width: 668px) {
  .gallery .block-title {
    font-size: 32px; }
  .gallery .gallery__element {
    flex-basis: 100%; } }

@media (max-width: 468px) {
  .gallery {
    padding: 25px 0 0 0; }
    .gallery .block-title {
      font-size: 29px; } }

.need {
  background: url("images/nead_trans1.jpg") top center no-repeat;
  background-size: cover;
  position: relative;
  padding: 90px 0; }
  .need::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  .need .need__des {
    font-size: 25px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 1.8px;
    color: #dc291a;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 10px 0 50px 0; }
  .need .need__wrap {
    max-width: 700px;
    margin: 0 auto; }
  .need .need__form {
    background: #DCD8D7;
    background: rgba(0, 13, 37, 0.5);
    padding: 4em 3em; }
    .need .need__form input[type="text"], .need .need__form input[type="tel"], .need .need__form textarea, .need .need__form .form-control {
      border: none;
      width: 100%;
      background: rgba(0, 13, 37, 0.7);
      padding: 10px 15px;
      margin-bottom: 15px;
      outline: none;
      font-size: 14px;
      color: #DCD8D7;
      letter-spacing: 1px;
      position: relative;
      z-index: 2;
      box-sizing: border-box; }
    .need .need__form input[type="text"]:focus, .need .need__form input[type="tel"]:focus, .need .need__form textarea {
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
    .need .need__form input[type="submit"] {
      width: 100%;
      margin-top: 0px;
      padding: 12px 0px;
      font-size: 15px;
      font-weight: 600;
      color: #DCD8D7;
      letter-spacing: 1px;
      text-transform: uppercase;
      border: none;
      background: rgba(220, 41, 26, 0.5);
      -webkit-transition: 0.5s all;
      -o-transition: 0.5s all;
      -moz-transition: 0.5s all;
      -ms-transition: 0.5s all;
      transition: 0.5s all;
      position: relative;
      z-index: 2; }
      .need .need__form input[type="submit"]:hover {
        background: #B24D48; }

@media (max-width: 995px) {
  .need {
    padding: 45px 0; } }

@media (max-width: 860px) {
  .need {
    padding: 30px 0; }
    .need .block-title {
      font-size: 36px; }
    .need .line {
      margin: 0px auto; }
    .need .need__des {
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 1.6px;
      margin: 20px 0 35px 0; } }

@media (max-width: 668px) {
  .need .block-title {
    font-size: 32px; }
  .need .need__des {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 1.4px;
    margin: 20px 0 35px 0; } }

@media (max-width: 468px) {
  .need {
    padding: 25px 0; }
    .need .block-title {
      font-size: 29px; }
    .need .need__des {
      margin: 20px 0 25px 0; } }

.clients {
  padding: 90px 0;
  background: url("images/client_bg.jpg") top center no-repeat;
  background-size: cover;
  position: relative; }
  .clients::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  .clients .cliens__wrap {
    position: relative;
    z-index: 2;
    padding-top: 40px; }
  .clients .clents__block .clients__elements img {
    display: block;
    max-width: 280px;
    margin: 0 auto;
    border: 2px solid #DCD8D7;
    box-sizing: border-box; }
  .clients .clents__block .clients__elements .client__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 1.2px;
    color: #DCD8D7;
    margin: 20px 0 0px 0px;
    text-transform: uppercase;
    text-align: center; }
  .clients .clents__block .clients__elements .client__info {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.75px;
    text-align: center;
    color: #DCD8D7;
    display: flex;
    margin: 20px 5px 40px 5px;
    border-top: 2px solid #DCD8D7;
    padding-top: 5px;
    box-sizing: border-box;
    opacity: 0.8; }
  .clients .slides {
    position: relative; }
  .clients .slick-dots li button {
    border: 1px solid #DCD8D7;
    border-radius: 50%; }
  .clients .slick-dots li button:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #0A0F1A;
    opacity: 1;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin: -5px 0 0 -5px; }
  .clients .slick-dots li button:hover,
  .clients .slick-dots li.slick-active button {
    border-color: #DCD8D7; }
  .clients .slick-dots li button:hover::before,
  .clients .slick-dots li.slick-active button::before {
    background: #DCD8D7; }

@media (max-width: 995px) {
  .clients {
    padding: 45px 0; } }

@media (max-width: 860px) {
  .clients {
    padding: 30px 0; }
    .clients .block-title {
      font-size: 36px; }
    .clients .line {
      margin: 0px auto; } }

@media (max-width: 668px) {
  .clients .block-title {
    font-size: 32px; } }

@media (max-width: 468px) {
  .clients {
    padding: 25px 0; }
    .clients .block-title {
      font-size: 29px; } }

.about {
  background: #DCD8D7;
  padding: 90px 0; }
  .about .about__elements {
    max-width: 1920px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 4; }
  .about .about__img {
    flex-basis: 50%;
    margin-right: 70px;
    position: relative; }
    .about .about__img::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 4; }
    .about .about__img img {
      width: 100%;
      display: block; }
  .about .about__info {
    flex-basis: calc(100% - 50% - 70px); }
  .about .info__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 2.04px;
    color: #252523;
    text-transform: uppercase;
    margin-bottom: 15px; }
  .about .line {
    width: 60px;
    margin: 10px 0 15px 0; }
    .about .line p::after {
      width: 60px;
      background-color: #03395f; }
  .about .info__des {
    letter-spacing: 0.8px;
    line-height: 25px;
    color: #434343;
    padding-right: 15px; }
  .about .about__button a {
    text-decoration: none;
    color: #DCD8D7;
    font-weight: 600;
    line-height: 30px;
    background-color: #03395f;
    border-radius: 3px;
    display: inline-block;
    padding: 8px 25px;
    margin: 30px 0;
    transition-duration: 300ms;
    transition-property: background-color; }
    .about .about__button a:hover {
      background-color: #0468A6; }

#slidingDiv {
  display: none; }

@media (max-width: 995px) {
  .about {
    padding: 45px 0; }
    .about .about__img {
      margin-right: 25px;
      align-self: center; }
    .about .about__info {
      flex-basis: calc(100% - 50% - 25px); } }

@media (max-width: 860px) {
  .about {
    padding: 30px 0; } }

@media (max-width: 750px) {
  .about__elements {
    flex-direction: column; }
    .about__elements .about__img {
      margin: 0 20px 30px 20px; }
    .about__elements .about__info {
      margin: 0 20px 0px 20px; } }

@media (max-width: 668px) {
  .about .info__title {
    font-size: 32px; } }

@media (max-width: 468px) {
  .about {
    padding: 25px 0; }
    .about .info__title {
      font-size: 29px; } }

.contacts {
  background: #ccc;
  padding: 90px 0; }
  .contacts .contacts__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .contacts .contacts__left {
    flex-basis: 40.8%;
    background-color: #DCD8D7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px/5px;
    padding: 20px 60px;
    box-sizing: border-box; }
  .contacts .contacts__right {
    flex-basis: 56.8%;
    background-color: #DCD8D7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px/5px;
    padding: 0px 60px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .contacts .contacts__right .contacts__form {
      flex-basis: 100%; }
  .contacts .contacts__info {
    margin: 35px 0; }
    .contacts .contacts__info p {
      text-align: left; }
    .contacts .contacts__info .contacts__info__title {
      font-size: 36px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 2.04px;
      color: #252523;
      text-transform: uppercase; }
    .contacts .contacts__info .line {
      width: 60px;
      margin: 10px 0 0px 0; }
      .contacts .contacts__info .line p::after {
        width: 60px;
        background-color: #03395f; }
    .contacts .contacts__info .contacts__des {
      font-size: 16px;
      color: #000F29;
      line-height: 30px;
      letter-spacing: 0.9px; }
      .contacts .contacts__info .contacts__des .contacts__des__mail {
        color: #000F29;
        text-decoration: none;
        cursor: pointer; }
        .contacts .contacts__info .contacts__des .contacts__des__mail:hover {
          text-decoration: underline; }
  .contacts .contacts__form .contacts__input, .contacts .contacts__form .contacts__textarea {
    position: relative;
    margin: 20px 0; }
    .contacts .contacts__form .contacts__input input, .contacts .contacts__form .contacts__input textarea, .contacts .contacts__form .contacts__textarea input, .contacts .contacts__form .contacts__textarea textarea {
      background: #DCD8D7;
      color: #03395f;
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;
      line-height: 30px;
      letter-spacing: 0.9px;
      width: 100%;
      border: none;
      border-bottom: 1px solid #03395f;
      box-shadow: none;
      padding: 7px 0; }
      .contacts .contacts__form .contacts__input input:focus + label, .contacts .contacts__form .contacts__input textarea:focus + label, .contacts .contacts__form .contacts__textarea input:focus + label, .contacts .contacts__form .contacts__textarea textarea:focus + label {
        transform: translateY(-20px);
        font-size: 14px; }
    .contacts .contacts__form .contacts__input textarea, .contacts .contacts__form .contacts__textarea textarea {
      resize: none;
      outline: none;
      height: 100px; }
    .contacts .contacts__form .contacts__input label, .contacts .contacts__form .contacts__textarea label {
      background: #DCD8D7;
      color: #03395f;
      font-size: 16px;
      font-family: 'Open Sans', sans-serif;
      line-height: 30px;
      letter-spacing: 0.9px;
      width: 100%;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition-duration: 300ms; }
  .contacts .contacts__form .contacts__button button {
    cursor: pointer;
    color: #DCD8D7;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    line-height: 30px;
    background-color: #03395f;
    border: none;
    border-radius: 3px;
    display: block;
    padding: 8px 25px;
    margin-top: 30px;
    transition-duration: 300ms;
    transition-property: background-color; }
    .contacts .contacts__form .contacts__button button:hover {
      background-color: #0468A6; }

@media (max-width: 995px) {
  .contacts {
    padding: 45px 0; }
    .contacts .contacts__left {
      padding: 30px; }
    .contacts .contacts__right {
      padding: 0 30px; } }

@media (max-width: 860px) {
  .contacts {
    padding: 30px 0; } }

@media (max-width: 770px) {
  .contacts .contacts__info {
    margin: 25px 0; }
  .contacts .contacts__left {
    flex-basis: 100%;
    margin: 15px 0; }
  .contacts .contacts__right {
    flex-basis: 100%;
    margin: 15px 0;
    padding: 30px; } }

@media (max-width: 668px) {
  .contacts .contacts__info .contacts__info__title {
    font-size: 32px; } }

@media (max-width: 468px) {
  .contacts {
    padding: 25px 0; }
    .contacts .contacts__info .contacts__info__title {
      font-size: 29px; } }

footer {
  background: #03395f; }
  footer .footer__wrap {
    padding: 40px; }
    footer .footer__wrap .footer__block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      footer .footer__wrap .footer__block .footer__block__element {
        flex-basis: 33%; }
        footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element {
          display: flex;
          align-items: center;
          margin-bottom: 15px; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element img {
            display: block;
            max-width: 100%;
            margin-right: 15px;
            border: 2px solid #DCD8D7;
            box-sizing: border-box; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element a {
            text-decoration: none;
            color: #DCD8D7;
            margin-bottom: 15px;
            display: block;
            cursor: pointer; }
            footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element a:hover {
              opacity: 0.6; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element .footer__top__news__date {
            font-size: 12px;
            color: #DCD8D7;
            opacity: 0.6; }
        footer .footer__wrap .footer__block .footer__block__element .news__block__title, footer .footer__wrap .footer__block .footer__block__element .footer__top__links__title,
        footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts__title {
          font-size: 22px;
          line-height: 30px;
          letter-spacing: 1.5px;
          color: #DCD8D7;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 25px;
          text-decoration: underline; }
        footer .footer__wrap .footer__block .footer__block__element .footer__top__services a {
          text-decoration: none;
          font-size: 18px;
          color: #DCD8D7;
          margin-bottom: 25px;
          display: block;
          cursor: pointer; }
          footer .footer__wrap .footer__block .footer__block__element .footer__top__services a:hover {
            opacity: 0.6; }
        footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__tel, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__email, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__address {
          font-size: 18px;
          color: #DCD8D7;
          margin-bottom: 25px;
          text-align: center;
          background: rgba(5, 77, 129, 0.6);
          padding: 20px 15px; }
  footer .footer__bottom {
    background: rgba(5, 77, 129, 0.6);
    padding: 30px 0;
    position: relative;
    z-index: 2; }
    footer .footer__bottom p {
      font-size: 14px;
      color: #DCD8D7;
      text-align: center; }
      footer .footer__bottom p a {
        text-decoration: none;
        color: #DCD8D7; }
        footer .footer__bottom p a:hover {
          text-decoration: underline;
          opacity: 0.6; }

@media (max-width: 1080px) {
  footer .footer__wrap .footer__block {
    justify-content: center; }
    footer .footer__wrap .footer__block .footer__block__element {
      flex-basis: 50%; }
      footer .footer__wrap .footer__block .footer__block__element .footer__top__news .footer__top__news_element img {
        margin-right: 25px; }
      footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__tel, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__email, footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts .contacts__address {
        text-align: left; }
      footer .footer__wrap .footer__block .footer__block__element .news__block__title, footer .footer__wrap .footer__block .footer__block__element .footer__top__links__title,
      footer .footer__wrap .footer__block .footer__block__element .footer__top__contacts__title {
        text-align: left; } }

@media (max-width: 768px) {
  footer .footer__wrap .footer__block .footer__block__element {
    flex-basis: 100%; } }

.arrow__up {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 20;
  opacity: 0.8; }

.scrollup {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  opacity: 0.6;
  display: none;
  text-indent: -9999px;
  border-radius: 5px;
  z-index: 10;
  cursor: pointer; }

.hed__services {
  background: #00274B;
  padding: 20px 0; }
  .hed__services .hed__services__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
  .hed__services .hed__services__menu-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-basis: 70%; }
  .hed__services .hed__services__menu {
    display: flex;
    flex-basis: 30%;
    justify-content: space-between; }
    .hed__services .hed__services__menu a {
      color: #DCD8D7;
      text-decoration: none;
      line-height: 30px;
      letter-spacing: 1.4px;
      padding: 10px 0;
      border-bottom: 2px solid transparent;
      transition-duration: 300ms;
      transition-property: color, border-bottom-color; }
      .hed__services .hed__services__menu a:hover {
        color: #0390FC;
        border-bottom-color: #0390FC;
        transition-duration: 300ms;
        transition-property: color, border-bottom-color; }
    .hed__services .hed__services__menu a.c-active {
      color: #0390FC;
      border-bottom: none; }
  .hed__services .hed__services__info {
    display: flex; }
    .hed__services .hed__services__info p img {
      vertical-align: middle;
      margin-right: 5px; }
    .hed__services .hed__services__info .top__telefon {
      color: #DCD8D7;
      font-weight: 600; }
    .hed__services .hed__services__info .top__email {
      margin-left: 15px; }
      .hed__services .hed__services__info .top__email a {
        color: #DCD8D7;
        text-decoration: none;
        letter-spacing: 0.6px; }
  .hed__services .hed__services__logo img:hover {
    opacity: 0.65;
    transition-duration: 300ms;
    transition-property: opacity; }

.serv-top-bg {
  position: relative; }
  .serv-top-bg::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(3, 80, 134, 0.4);
    z-index: 4; }
  .serv-top-bg img {
    width: 100%;
    display: block; }

.all__services__wrap {
  margin-bottom: 30px; }

.all__services {
  background: #ccc;
  padding: 30px 0; }
  .all__services .block-title {
    color: #252523;
    font-size: 35px; }
  .all__services .line {
    width: 70px;
    margin: 10px auto 0px auto; }
    .all__services .line p::after {
      background-color: #252523; }

.all__services__block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 50px; }
  .all__services__block .all__services__element__img {
    flex-basis: 50%;
    margin-right: 35px;
    position: relative; }
    .all__services__block .all__services__element__img::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 4; }
    .all__services__block .all__services__element__img img {
      width: 100%;
      display: block; }
  .all__services__block .all__services__element__info {
    flex-basis: calc(100% - 50% - 35px);
    letter-spacing: 0.8px;
    line-height: 25px;
    color: #434343; }
    .all__services__block .all__services__element__info span {
      color: #E05555;
      font-weight: 600; }

@media (max-width: 960px) {
  .hed__services .hed__services__logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px; }
  .hed__services .hed__services__menu-info {
    flex-basis: 100%; }
  .all__services__wrap .block-title {
    line-height: 40px; }
  .all__services__wrap .all__services__block {
    padding-top: 30px; }
  .all__services__wrap .all__services__element__img {
    margin-right: 25px; }
  .all__services__wrap .all__services__element__info {
    flex-basis: calc(100% - 50% - 25px); } }

@media (max-width: 868px) {
  .all__services__wrap .block-title {
    font-size: 30px;
    line-height: 30px; }
  .all__services__block {
    flex-direction: column; }
    .all__services__block .all__services__element__img {
      order: -1;
      margin: 0 auto 20px auto; }
    .all__services__block .all__services__element__info {
      text-align: justify; } }

@media (max-width: 625px) {
  .hed__services .hed__services__logo img {
    width: 220px; }
  .hed__services .hed__services__menu {
    flex-basis: 100%;
    justify-content: center; }
    .hed__services .hed__services__menu p {
      margin-right: 20px; }
  .hed__services .hed__services__info {
    padding-top: 10px;
    flex-basis: 100%;
    justify-content: center; }
  .all__services__wrap .block-title {
    font-size: 25px;
    line-height: 30px; }
  .all__services__block .all__services__element__info {
    text-align: left; } }

@media (max-width: 378px) {
  .hed__services__menu p,
  .hed__services__info p {
    font-size: 14px; }
  .all__services__wrap .block-title {
    font-size: 22px;
    line-height: 22px; } }
