@import "variables";
@import "mixins";

.arrow__up{
	width: 60px;
	height: 60px;
	position: fixed;
	right: 30px;
	bottom: 30px;
	z-index: 20;
	opacity: 0.8;
}