@import "variables";
@import "mixins";

.clients{
	padding: 90px 0;
  	background: url('images/client_bg.jpg') top center no-repeat;
  	background-size: cover;
  	position: relative;

  	&::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; 
} 

.cliens__wrap{
		position: relative;
	    z-index: 2;
	    padding-top: 40px;
	}

.clents__block{

		.clients__elements{
		img{
				 display: block;
				max-width: 280px;
				margin: 0 auto;
				border: 2px solid $gree-color;
				box-sizing: border-box;
			}

			.client__title{
			font-size: 18px;
			font-weight: 700;
			line-height: 25px;
			letter-spacing: 1.2px;
			color: $gree-color;
			margin: 20px 0 0px 0px;
			text-transform: uppercase;
			text-align: center;
			}

			.client__info{
			font-size: 15px;
			line-height: 20px;
			letter-spacing: 0.75px;
			text-align: center;
			color: $gree-color;
			display: flex;
			margin: 20px 5px 40px 5px;
			border-top: 2px solid $gree-color;
			padding-top: 5px;
			box-sizing: border-box;
			opacity: 0.8;
			}
		}
	}

	// SLIDER
	.slides{
  	position: relative;
}

.slick-dots li button{
  border: 1px solid $gree-color;
  border-radius: 50%;
}
.slick-dots li button:before{
  content: '';
  width: 10px;
  height: 10px;
  background: #0A0F1A;
  opacity: 1;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -5px;
}
.slick-dots li button:hover,
.slick-dots li.slick-active button
{
border-color: $gree-color;
}
.slick-dots li button:hover::before,
.slick-dots li.slick-active button::before{
background: $gree-color;
}

}

@media (max-width: 995px) {

        .clients{
            padding: 45px 0;
        }
}

@media (max-width: 860px) {

        .clients{
            padding: 30px 0;

            .block-title{
            font-size: 36px;
            }
            .line{
            margin: 0px auto;   
            }
        }
}

@media (max-width: 668px) {

        .clients{
			
            .block-title{
            font-size: 32px;
            }
        }
}

@media (max-width: 468px) {

        .clients{
        	padding: 25px 0;

            .block-title{
            font-size: 29px;
            }
        }
}