@import "variables";
@import "mixins";

.scrollup{
width: 60px;
height:60px;
position:fixed;
bottom:50px;
right:50px;
opacity: 0.6;
display:none;
text-indent:-9999px;
border-radius: 5px;
z-index: 10;
cursor: pointer;
}

.hed__services{
	background: #00274B;
	padding: 20px 0;
.hed__services__block{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
}
.hed__services__menu-info{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		flex-basis: 70%;
}
.hed__services__menu{
		display: flex;
		flex-basis: 30%;
		justify-content: space-between;

		a{
				color: $gree-color;
				text-decoration: none;
				line-height: 30px;
				letter-spacing: 1.4px;
				padding: 10px 0;
				border-bottom: 2px solid transparent;
				transition-duration: 300ms;
				transition-property: color, border-bottom-color;
				&:hover{
					color: #0390FC;
					border-bottom-color: #0390FC;
					transition-duration: 300ms;
					transition-property: color, border-bottom-color;
				}
		}

		a.c-active{
			color: #0390FC;
			border-bottom: none;
		}
}

.hed__services__info{
		display: flex;
		
		p{
			
			img{
				vertical-align: middle;
				margin-right: 5px;
			}

		}

		.top__telefon{
			color:  $gree-color;
			font-weight: 600;

		}

		.top__email{
			margin-left: 15px;

			a{
				color: $gree-color;
				text-decoration: none;
				letter-spacing: 0.6px;
			}

		}
}
	
	.hed__services__logo img:hover{
		opacity: 0.65;
		transition-duration: 300ms;
		transition-property: opacity;
	}

}

.serv-top-bg{
position: relative;

				&::after{
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-color: rgba(3, 80, 134, 0.4);
		z-index: 4;
	}

		img{
			width: 100%;
			display: block;
		}

}

.all__services__wrap{
	margin-bottom: 30px;
}

.all__services{
	background: #ccc;
  	padding: 30px 0;

  	.block-title{
		color: $title-color;
		font-size: 35px;
	}

	.line{
		width: 70px;
		margin: 10px auto 0px auto;
		p{
		&::after{
			background-color: $title-color;
		}
	}
}
}

.all__services__block{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-top: 50px;

		.all__services__element__img{
			flex-basis: 50%;
		margin-right: 35px;
		position: relative;

				&::after{
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 4;
		}

		img{
			width: 100%;
			display: block;
		}
	}

	.all__services__element__info{
		flex-basis: calc(100% - 50% - 35px);
		letter-spacing: 0.8px;
		line-height: 25px;
		color: $desc-color;

		span{
			color: #E05555;
			font-weight: 600;
		}
	
	}
}

@media (max-width: 960px) {

	.hed__services{

		.hed__services__logo{
			flex-basis: 100%;
			text-align: center;
			margin-bottom: 10px;
	}

	.hed__services__menu-info{
		flex-basis: 100%;
	}

	}

	.all__services__wrap{

		.block-title{
			line-height: 40px;
		}
		.all__services__block{
			padding-top: 30px;
		}
		.all__services__element__img{
			margin-right: 25px;
	}
		.all__services__element__info{
		flex-basis: calc(100% - 50% - 25px);
}
}	
}

@media (max-width: 868px) {
	.all__services__wrap{
		.block-title{
			font-size: 30px;
			line-height: 30px;
		}
}
	.all__services__block{
		flex-direction: column;

		.all__services__element__img{
			order: -1;
			margin: 0 auto 20px auto;
		}

		.all__services__element__info{
			text-align: justify;
		}
	}

}

@media (max-width: 625px) {
	.hed__services{

		.hed__services__logo{
			img{
				width: 220px;
			}
		}

		.hed__services__menu{
			flex-basis: 100%;
			justify-content: center;
			p{
				margin-right: 20px;


			}
		}

		.hed__services__info{
			padding-top: 10px;
			flex-basis: 100%;
			justify-content: center;
			
		}
	}

	.all__services__wrap{
		.block-title{
			font-size: 25px;
			line-height: 30px;
		}
}

.all__services__block{
		.all__services__element__info{
			text-align: left;
		}
	}

}

@media (max-width: 378px) {
		.hed__services__menu,
		.hed__services__info{
			
			p{
				font-size: 14px;
			}
	}

	.all__services__wrap{
		.block-title{
			font-size: 22px;
			line-height: 22px;
		}
}
}


