@import "variables";
@import "mixins";

footer{
	background: rgba(3, 57, 95, 1);

	.footer__wrap{
		padding: 40px;
		
		.footer__block{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.footer__block__element{
			flex-basis: 33%;

			.footer__top__news{

		.footer__top__news_element{
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			img{
				display: block;
				max-width: 100%;
				margin-right: 15px;
				border: 2px solid $gree-color;
				box-sizing: border-box;
			} 
			
			a{
				text-decoration: none;
				color: $gree-color;
				margin-bottom: 15px;
				display: block;
				cursor: pointer;

				&:hover{
					opacity: 0.6;
				}
			}

			.footer__top__news__date{
				font-size: 12px;
				color:$gree-color;
				opacity: 0.6;
			}


		}
	}//.footer__top__news

		.news__block__title, .footer__top__links__title, 
		.footer__top__contacts__title{
		font-size: 22px;
		line-height: 30px;
		letter-spacing: 1.5px;
		color: $gree-color;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 25px;
		text-decoration: underline;
	}

	.footer__top__services{
		a{
			text-decoration: none;
				font-size: 18px;
				color: $gree-color;
				margin-bottom: 25px;
				display: block;
				cursor: pointer;

			&:hover{
				opacity: 0.6;
			}	
		}
	}

		.footer__top__contacts{

		.contacts__tel, .contacts__email, .contacts__address{
				font-size: 18px;
				color: $gree-color;
				margin-bottom: 25px;
				text-align: center;
				background: rgba(5,77,129, 0.6);
				padding: 20px 15px;
		}
	}
}//footer__block__element

}//.footer__block

}//.footer__top

	.footer__bottom{
		background: rgba(5,77,129, 0.6);
		padding: 30px 0;
		position: relative;
		z-index: 2; 

		p{
		font-size: 14px;
		color: $gree-color;
		text-align: center;
		a{
			text-decoration: none;
			color: $gree-color;
			&:hover{
				text-decoration: underline;
				opacity: 0.6;
			}
		}

		}
	}//.footer__bottom
	
}//.footer

@media (max-width: 1080px) {
	footer{
		.footer__wrap{
			.footer__block{
				justify-content: center;

				.footer__block__element{
				flex-basis: 50%;

				.footer__top__news{

		.footer__top__news_element{
			
			img{
				margin-right: 25px;
			} 
			
		}
		}


			.footer__top__contacts{

		.contacts__tel, .contacts__email, .contacts__address{
				
				text-align: left;
				
		}
	}

				.news__block__title, .footer__top__links__title, 
				.footer__top__contacts__title{
					text-align: left;
				}
			}

			}
		}
	}
}

@media (max-width: 768px) {
	footer{
		.footer__wrap{
			.footer__block{
				.footer__block__element{
				flex-basis: 100%;
		
			}
			}
		}
	}
}



 