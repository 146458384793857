@import "variables";
@import "mixins";

.contacts{
	background: #ccc;
  	padding: 90px 0;

  	.contacts__block{
  		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
  	}

  	.contacts__left{
  		flex-basis: 40.8%;
  		background-color: $gree-color;
  		box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
  		border-radius: 2px/5px;
  		padding: 20px 60px;
  		box-sizing: border-box;
  	}

  	.contacts__right{
  		flex-basis: 56.8%;
  		background-color: $gree-color;
  		box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
  		border-radius: 3px/5px;
  		padding: 0px 60px;
  		box-sizing: border-box;
  		display: flex;
  		flex-wrap: wrap;
  		align-items: center;

  		.contacts__form{
			flex-basis: 100%;
  		}
  	}

  	.contacts__info{
  		margin: 35px 0;

  		p{
  			text-align: left;
  		}

  		.contacts__info__title{
  		font-size: 36px;
		font-weight: 700;
		line-height: 50px;
		letter-spacing: 2.04px;
		color: $title-color;
		text-transform: uppercase;
  		}

  		.line{
		width: 60px;
		margin: 10px 0 0px 0;
		p{
		&::after{
			width: 60px;
			background-color: rgba(3, 57, 95, 1);
		}
	}
	}
		.contacts__des{
			font-size: 16px;
			color: #000F29;
			line-height: 30px;
			letter-spacing: 0.9px;

			.contacts__des__mail{
			color: #000F29;
			text-decoration: none;
			cursor: pointer;
			&:hover{
				text-decoration: underline;
			}	
			}
		}
  	}

  	.contacts__form{


  		.contacts__input, .contacts__textarea{
  			position: relative;
  			margin: 20px 0;

  			input, textarea{
  				background: $gree-color;
  				color: rgba(3, 57, 95, 1);
  				font-size: 16px;
  				font-family: 'Open Sans', sans-serif;
  				line-height: 30px;
				letter-spacing: 0.9px;
  				width: 100%;
  				border: none;
  				border-bottom: 1px solid rgba(3, 57, 95, 1);
  				box-shadow: none;
  				padding: 7px 0;
				
  				&:focus + label{
  					transform: translateY(-20px);
  					font-size: 14px;
  				} 
  			}

  			textarea{
  				resize: none;
  				outline: none;
  				height: 100px;
  			}

  			label{
  				background: $gree-color;
  				color: rgba(3, 57, 95, 1);
  				font-size: 16px;
  				font-family: 'Open Sans', sans-serif;
  				line-height: 30px;
				letter-spacing: 0.9px;
  				width: 100%;
  				position: absolute;
  				pointer-events: none;
  				left: 0;
  				top: 5px;
  				white-space: nowrap;
  				overflow: hidden;
  				text-overflow: ellipsis;
				transition-duration: 300ms;
  			}
  		}

  		.contacts__button{
  			button{
  			cursor: pointer;
			color: $gree-color;
			font-size: 16px;
			font-family: 'Open Sans', sans-serif;
			font-weight: 600;
			line-height: 30px;
			background-color: rgba(3, 57, 95, 1);
			border: none;
			border-radius: 3px;
			display: block;
			padding: 8px 25px;
			margin-top: 30px;
			transition-duration: 300ms;
			transition-property: background-color;
			&:hover{
				background-color: $blue-color;
			}
  			}
  		}
  	}
}

@media (max-width: 995px) {
	.contacts{
		padding: 45px 0;

		.contacts__left{
		padding: 30px;
	}

		.contacts__right{
		padding: 0 30px;
	}
}
}

@media (max-width: 860px) {
        .contacts{
             padding: 30px 0;
         }
}

@media (max-width: 770px) {
        .contacts{
           .contacts__info{
           	margin: 25px 0;
           }
		.contacts__left{
		flex-basis: 100%;
		margin: 15px 0;
	}
		.contacts__right{
		flex-basis: 100%;
		margin: 15px 0;
		padding: 30px;
	}	
    }
}

@media (max-width: 668px) {
    .contacts{
    	.contacts__info{
    		.contacts__info__title{
    			font-size: 32px;
    		}
    	}

	}
}

@media (max-width: 468px) {
    .contacts{
    	padding: 25px 0;
    	.contacts__info{
    		.contacts__info__title{
    			font-size: 29px;
    		}
    	}

	}
}