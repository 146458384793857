@import "variables";
@import "mixins";

header{
	background: url('images/header_bg2.jpg') top center no-repeat;
  	background-size: cover;
  	position: relative;

  	&::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; 
}

	.header__top{
		padding: 20px 0;
		background: rgba(3, 80, 134, 0.5);
	    position: relative;
	    z-index: 5;
	}

	.header__top__section{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}



	.heder__top__logo img:hover{
		opacity: 0.65;
		transition-duration: 300ms;
		transition-property: opacity;
	}

	.header__top__menu{
		flex-basis: 63%;

		a.c-active{
			color: #0390FC;

			&:hover{
				color: $gree-color;
				border-bottom-color: $gree-color;
			}
		}
	}

	.header__content{
		position: relative;
	    z-index: 2;

	    .line{
	    	margin: 140px 0 20px 0;
	    	p{
				&::after{
					background-color: $gree-color;
				}
	}
	    }

	    h1{
	    	font-size: 60px;
	    	font-weight: 700;
	    	line-height: 75px;
	    	letter-spacing: 3.5px;
			color: $gree-color;
			text-transform: uppercase;
			margin-bottom: 105px;
	    }

	    .header__icon{
			max-width: 62px;
			margin: 0px auto ;

	    	a{
    		display: block;
			&:hover{
				img{
					animation-duration: 3s;
					  animation-delay: 2s;
					  animation-iteration-count: infinite;
				}

			}

				

	    	}
	    }
	}
}

@media (max-width: 1200px) {
	header{

		.header__top__section{


			.heder__top__logo{
				flex-basis: 100%;
				text-align: center;
				margin-bottom: 10px;
			}

			.header__top__menu{
				flex-basis: 100%;
			}

		}

		.header__content{
			.line{
				margin: 60px 0 20px 0;
			}

			h1{
				font-size: 48px;
				margin-bottom: 50px;
			}
		}
	}

}

@media (max-width: 668px) {
	header{

		.header__top__section{

			.heder__top__logo{
				margin-bottom: 20px;
			}
	}

		.header__content{
			.line{
				margin: 60px 0 20px 0;
			}

			h1{
				margin-bottom: 30px;
			}
		}
	}
}

@media (max-width: 468px) {
		header{

		.header__top__section{

			.heder__top__logo{
				margin-bottom: 15px;
			}
	}

		.header__content{
			.line{
				margin: 40px 0 20px 0;
			}

			h1{
				font-size: 45px;
				margin-bottom: 25px;
			}
		}
	}
}

